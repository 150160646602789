import isUndefined from 'lodash/fp/isUndefined';
import { type MapChargingStation, renderChargingPoints } from '../actions/renderChargingPoints';
import { selectFilteredStations } from '../../filteredStations/filteredStationsSelector';
import { store } from '../../../configuration/setup/store';
import { selectIsSearchWidget } from '../../app/appSelectors';

export const RENDER_STATIONS_EVENT = 'EVENT_RENDER_STATIONS';

export type RenderStationsPayload = MapChargingStation[] | undefined;

export const renderStationsHandler = (data: RenderStationsPayload) => {
    const isSearchWidget = selectIsSearchWidget(store.getState());
    if (!isSearchWidget) {
        return;
    }

    if (isUndefined(data)) {
        const stations = selectFilteredStations(store.getState());
        renderChargingPoints(stations);
    } else {
        renderChargingPoints(data);
    }
};
