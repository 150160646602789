import isNil from 'lodash/fp/isNil';

import { store } from '../../../configuration/setup/store';
import { trace } from '../../../configuration/setup/trace';
import { selectWidget } from '../widgetSelectors';
import { updateWidgetVisibility } from '../widgetSlice';

export const VISIBLE_WIDGETS_EVENT = 'EVENT_SET_VISIBLE_WIDGETS';

type VisibleWidgetsType = string[];

export const visibleWidgetsHandler = (visibleWidgets: VisibleWidgetsType) => {
    trace.debug('Set Visible widgets:', visibleWidgets);

    const widgetState = selectWidget(store.getState());

    if (isNil(widgetState.widgetId)) {
        return;
    }

    const isVisible = visibleWidgets.includes(widgetState.widgetId);

    if (isVisible === true && widgetState.isVisible === false) {
        store.dispatch(updateWidgetVisibility(true));
    }

    if (isVisible === false && widgetState.isVisible === true) {
        store.dispatch(updateWidgetVisibility(false));
    }
};
